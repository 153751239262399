import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image';
import SideBar from '../../components/sideBar/SideBar';

const activities = [
    {
        text: 'We create virtual 3D/BIM models accompanied by databases. Our models are useful throughout the whole life cycle of a building: from construction planning to commercialization and operational phase.',
        image: {
            src: 'activity1.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'The end-product of our work is a model of the building accompanied by the information resources for the investor, the main contractor, and the property manager – all prepared according to our clients’ needs.',
        image: {
            src: 'activity2.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'The highest quality visual database can be used and processed by architects, IT professsionals, and Facility Management.',
        image: {
            src: 'activity3.jpg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
]

const Activity = props => {

    return (
        <>
            <div className='activities__container position-relative' id="activity">
            <div className="under-title under-title--big">About Us</div>
            <SideBar section={ 0 }/>
                <div className='container'>
                    <div className='activities position-relative'>
                        <h3 className='title'>What do we do</h3>
                        <p className='subtitle xl-normal'>
                            We apply the newest BIM technology-based solutions to&#160;the investment processes in&#160;the industry and construction sectors.
                        </p>
                    </div>
                    <div className='activity__container'>
                        {activities.map((activity) => {
                            return (
                                <div className='activity' key={activity.text}>
                                    <div className='activity__left'>
                                        <Image src={activity.image.src}
                                            className='mx-auto shadow-xl'
                                            alt={activity.image.alt} />
                                    </div>
                                    <div className='activity__right xl-normal'>
                                        <p dangerouslySetInnerHTML={{__html: activity.text}}></p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


export default Activity;