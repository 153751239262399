import React from 'react';
import './styles/styles.scss';
import Accordion from './components/Accordion';
import SideBar from '../../components/sideBar/SideBar';
import { useStaticQuery, graphql } from "gatsby";

let panels = [
        {
            title: 'I-21/10S 3D model of industrial transporting lines, FR',
            subtitle: 'Development of transporting lines. Executing 3D scanning and creating a&#160;model of&#160;the existing infrastructure to&#160;support the project coordination; revisions of&#160;the layouts.',
            content: 'Onsite work. Improving managers’ and&#160;engineers’ workflows during refurbishment works during the&#160;designing, preparation, and&#160;installation phases.',
            image1: 'studies1-1.jpg',
            image2: 'studies1-2.jpg',
            image3: 'studies1-3.jpg',
            value1: '15000',
            value1description: 'sqm of the conveyor belts area',
            value2: '1200',
            value2description: 'scanner positions in the operating facility',
            value3: '25 000',
            value3description: 'model elements',
            value4: '900',
            value4description: 'm of the conveyor belts',
        },
        {
            title: 'I-20/15S Coordination of&#160;a&#160;production plant segment’s development, PL',
            subtitle: 'Revision of&#160;the&#160;documentation (layouts) in&#160;place using the&#160;3D/BiM model to&#160;support the&#160;production expansion needs.',
            content: 'Providing client support in&#160;the&#160;matter of&#160;technical possibilities of&#160;facility reconstruction and&#160;of&#160;changes in&#160;the&#160;working units’ arrangement. Analysis of&#160;the&#160;reconstruction variants in&#160;the&#160;view of&#160;technology processes. Virtual analysis of&#160;the&#160;project feasibility. Indicating the&#160;collision between the&#160;existing',
            image1: 'studies2-1.jpg',
            image2: 'studies2-2.jpg',
            image3: 'studies2-3.jpg',
            value1: '3100',
            value1description: 'm of production installations',
            value2: '630',
            value2description: 'working units and components of the production lines',
            value3: '2240',
            value3description: 'model elements',
            value4: '500',
            value4description: 'm conveyor belts',
        },
        {
            title: 'L-21/4J As-built BIM model of a logistics park, PL',
            subtitle: '3D scanning and&#160;creation of&#160;an&#160;as-built BIM model of&#160;a&#160;110 000 sqm&#160;joint surface.',
            content: 'The model includes elements from all&#160;industry categories, supplemented with data from technical specification and&#160;project documentation. The&#160;model created based on&#160;the&#160;point cloud, as&#160;well as&#160;on&#160;the&#160;as-built design used for&#160;verification of&#160;the&#160;completed investment during the&#160;occupation permit phase. The model was&#160;used to&#160;support the&#160;facility management systems.',
            image1: 'studies3-1.jpg',
            image2: 'studies3-2.jpg',
            image3: 'studies3-3.jpg',
            value1: '115 000',
            value1description: 'sqm area / 9 000 sqm of the office area',
            value2: '5500',
            value2description: 'scanner positions at the delivery stage',
            value3: '550',
            value3description: 'reinforced concret posts, 12 x 22 m netting',
            value4: '2000',
            value4description: 'sprinkler heads in the industrial hall',
        },
        {
            title: 'W-42/8P As-built BIM model of&#160;a&#160;storage hall, PL',
            subtitle: '3D scanning and creation of&#160;an&#160;as-built BIM model of&#160;an&#160;over 60 000 sqm surface.',
            content: 'Multi-industry-specific model made simultaneously with the&#160;creation of&#160;the&#160;as-built documentation, based on&#160;the&#160;point cloud, including architecture, construction, sewage systems, HVAC, electricity, and&#160;lightning protection. At&#160;this stage, the&#160;purpose of&#160;building the&#160;model was&#160;to&#160;verify the&#160;quality of&#160;construction works against the&#160;project documentation.',
            image1: 'studies4-1.jpg',
            image2: 'studies4-2.jpg',
            image3: 'studies4-3.jpg',
            value1: '65 000',
            value1description: 'sqm surface',
            value2: '14 000',
            value2description: 'pages of documentation',
            value3: '2300',
            value3description: 'scanner positions',
            value4: '8 000',
            value4description: 'm of structural beams',
        },
    ];


export default function CaseStudies() {
//     const data = useStaticQuery(graphql`
//         query {
//             allStrapiRealization {
//                 nodes {
//                 id
//                 image1 {
//                     url
//                 }
//                 image2 {
//                     url
//                 }
//                 image3 {
//                     url
//                 }
//                 subtitle
//                 title
//                 content
//                 value1
//                 value1description
//                 value2
//                 value2description
//                 value3
//                 value3description
//                 value4
//                 value4description
//                 }
//             }
//         }
// `);

    return (
        <>
            <div className='caseStudies position-relative' id='caseStudies'>
                <SideBar section={4} />
                <div className='container caseStudies__container'>
                    <h3 className='title white'>Case studies</h3>
                    <Accordion panels={panels} />
                </div>
            </div>
        </>
    )
}
