import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';

const cooperations = [
    {
        text: 'Leverage our wide experience and secure the successful execution of&#160;your project.',
        image: {
            src: 'cooperation1.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Are able to&#160;choose the&#160;optimal approach from the&#160;current market technology solutions.',
        image: {
            src: 'cooperation2.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Get individual, highest quality service, tailored to&#160;the&#160;project requirements.',
        image: {
            src: 'cooperation3.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
    {
        text: 'Are supported with the&#160;information models during the&#160;database implementation phase.',
        image: {
            src: 'cooperation4.svg',
            className: 'mx-auto shadow-xl',
            alt: 'image construcion'
        }
    },
]
const Cooperation = () => {

    return (
        <>
            <div className='cooperations position-relative' id="cooperations">
                <SideBar section={ 3 }/>
                <div className='container  cooperations__container'>
                    <h3 className='title'>With us,&#160;you:</h3>
                    <div className='cooperations__section'>
                        {cooperations.map((cooperation, index) => {
                            return (
                                <div className='cooperation' key={index}>
                                    <div className='cooperation__top'>
                                        <Image src={cooperation.image.src}
                                            className={cooperation.image.className}
                                            alt={cooperation.image.alt} />
                                    </div>
                                    <div className='cooperation__bottom xl-small'>
                                        <p dangerouslySetInnerHTML={{__html: cooperation.text}}></p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cooperation;