import React from 'react';
import Activity from '../sections/activity/Activity';
import CaseStudies from '../sections/caseStudies/CaseStudies';
import Cooperation from '../sections/cooperation/Cooperation';
import Header from '../sections/header/Header';
import Operations from '../sections/operations/Operations';
import Pill from '../sections/pill/Pill';
import Details from '../sections/details/Details';
import Footer from '../sections/footer/Footer';
import Video from '../sections/video/Video';
import Piz from '../sections/piz/Piz';
import { Helmet } from "react-helmet"
import './styles/styles.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet title="Digital Building Data">
        <meta name="description" content="Digital Building Data" />
        <link href="https://api.fontshare.com/v2/css?f[]=satoshi@700,500,400&display=swap" rel="stylesheet" />
        <link rel="shortcut icon" type="../images/favicon.ico'" href="favicon.ico" />
      </Helmet>
      <Header />
      <Activity />
      <Pill />
      <Operations />
      <Cooperation />
      <CaseStudies />
      <Details />
      <Footer />
    </>
  )
}

export default IndexPage