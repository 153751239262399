import React from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';

export default function Operations() {

    return (
        <>
            <div className='operations position-relative' id='operations'>
                <div className="under-title">Fields of Activity</div>
                <SideBar section={2} />
                <div className='container operations__container position-relative'>
                    <h3 className='title'>Fields of Activity</h3>
                    <div className='operations__section'>
                        <div className='operation'>
                            <Image src='operation1.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Models of&#160;large-volume and engineer buildings for&#160;the&#160;industrial sector.
                                </p>
                            </div>
                        </div>
                        <div className='operation'>
                            <Image src='operation2.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Models of&#160;technology lines to&#160;support the&#160;development’s coordination.
                                </p>
                            </div>
                        </div>
                        <div className='operation operation--top'>
                            <Image src='operation3.png'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Integration of&#160;the&#160;building data into various systems, including the&#160;FM platforms.
                                </p>
                            </div>
                        </div>
                        <div className='operation operation--top'>
                            <Image src='operation4.jpg'
                                className='mx-auto shadow-xl'
                                alt='logo' />
                            <div className='operation-frame'>
                                <p>
                                    Measurement of&#160;facilities using 3D&#160;scanning method.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}