import React, { Component } from 'react';
import './styles/styles.scss';
import Image from '../../components/image/Image'
import SideBar from '../../components/sideBar/SideBar';
import backgroundVideo from '../../images/videoBackground.mp4'

class Pill extends Component {

    componentDidMount() {
        document.querySelector('.fullscreen-bg__video').playbackRate = 0.8;
    }

    render() {

        return (
            <>
                <div className='position-relative'>
                    <div className='videoSection'>
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className='fullscreen-bg__video'
                            src={backgroundVideo}>
                            <source src={backgroundVideo} type="video/mp4" />
                        </video>
                    </div>
                    <div className='pill position-relative' id='pill'>
                        <SideBar section={1} />
                        <div className='container pill__container'>
                            <div className='pill__section'>
                                <div className='pill__left'>
                                    <Image src='big-logo5.svg'
                                        className='mx-auto shadow-xl'
                                        alt='logo' />
                                </div>
                                <div className='pill__right'>
                                    <p className='title'>
                                        We prepare virtual models based on&#160;various data: from point clouds to&#160;both technical and&#160;project documentation.
                                    </p>
                                    <span className='subtitle xl-small'>
                                        We use universal modeling tools and&#160;solutions useful across various standards and&#160;work environments. We&#160;share knowledge and&#160;experience with our&#160;partners.
                                    </span>
                                    <span className='subtitle xl-small'>
                                        In our work, we leverage the Building Information Modeling / Management (BIM) technology. Our service offering spans on&#160;all&#160;stages of&#160;the&#160;investment process.
                                    </span>
                                    <div className='pills'>
                                        <div className='pill__item'>
                                            <Image src='pill1.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                We verify data from construction, executive, and&#160;as-built documentations.
                                            </p>
                                        </div>
                                        <div className='pill__item'>
                                            <Image src='pill2.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                We check the&#160;accordance of&#160;the property’s actual condition against the&#160;3D&#160;scan data.
                                            </p>
                                        </div>
                                        <div className='pill__item'>
                                            <Image src='pill3.svg'
                                                className='mx-auto shadow-xl'
                                                alt='icon' />
                                            <p className='xl-small'>
                                                We systemize information about the&#160;building in&#160;a&#160;user-friendly manner for&#160;the&#160;purpose of&#160;spatial and&#160;financial analyses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Pill;