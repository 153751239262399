import React from "react";
import { useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import Image from '../../components/image/Image'
import './styles/styles.scss';
import backgroundVideo from '../../images/detailsBackground.mp4'

export default function Details() {
    let counts = [
        {
            label: 'of BIM models',
            number: "500000",
            unit: 'sqm',
        },
        {
            label: 'months of experience',
            number: "130",
        },
        {
            label: 'completed projects',
            number: "160",
        },
        {
            label: 'analyzed guaranty cards',
            number: "4500",
        },
        {
            label: 'hours of modelling',
            number: "80640",
        },
    ];

    const [viewPortEntered, setViewPortEntered] = useState(false);

    return (
        <>
            <div className="position-relative">
                <div className='videoSection'>
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className='fullscreen-bg__video'
                        src={backgroundVideo}>
                        <source src={backgroundVideo} type="video/mp4" />
                    </video>
                </div>
                <div className='details__container' id='details'>
                    <div className='details'>
                        {counts && (
                            counts.map((count, index) =>
                                <div className="item" key={index}>
                                    <div className="text-left">
                                        <CountUp start={viewPortEntered ? null : 0} end={count.number}>
                                            {({ countUpRef }) => {
                                                return (
                                                    <VisibilitySensor
                                                        active={!viewPortEntered}
                                                        onChange={isVisible => {
                                                            if (isVisible) {
                                                                setViewPortEntered(true);
                                                            }
                                                        }}
                                                        delayedCall>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                );
                                            }}
                                        </CountUp>
                                        <span className="unit">{count.unit}</span>
                                    </div>
                                    <p className="text-left">{count.label}</p>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}