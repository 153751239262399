import React from 'react';
import './styles/styles.scss';
import Navigation from './components/navigation/Navigation';
import SideBar from '../../components/sideBar/SideBar';



export default function Header() {

    return (
        <div className='header__container position-relative'>
            <SideBar section={'header'} />
            <div className='container navigation_container'>
                <Navigation/>
                <div className='header'>
                    <div className='header__left xl-big'>
                        <p className='white'>Information</p>
                        <p className='blue'>& Technology</p>
                    </div>
                    <div className='header__right'>
                        <p className='white xl-normal'>
                            We create databases using the 3d/BIM modelling for&#160;various purposes, according to&#160;your needs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}