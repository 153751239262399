import A0 from "./8_1000.png";
import A1 from "./8_1005.png";
import A2 from "./8_1010.png";
import A3 from "./8_1015.png";
import A4 from "./8_1020.png";
import A5 from "./8_1025.png";
import A6 from "./8_1030.png";
import A7 from "./8_1035.png";
import A8 from "./8_1040.png";
import A9 from "./8_1045.png";
import A10 from "./8_1050.png";
import A11 from "./8_1055.png";
import A12 from "./8_1060.png";
import A13 from "./8_1065.png";
import A14 from "./8_1070.png";
import A15 from "./8_1075.png";
import A16 from "./8_1080.png";
import A17 from "./8_1085.png";
import A18 from "./8_1090.png";
import A19 from "./8_1095.png";
import A20 from "./8_1100.png";
import A21 from "./8_1105.png";
import A22 from "./8_1110.png";
import A23 from "./8_1115.png";
import A24 from "./8_1120.png";
import A25 from "./8_1125.png";
import A26 from "./8_1130.png";
import A27 from "./8_1135.png";
import A28 from "./8_1140.png";
import A29 from "./8_1145.png";
import A30 from "./8_1150.png";
import A31 from "./8_1155.png";
import A32 from "./8_1160.png";
import A33 from "./8_1165.png";
import A34 from "./8_1170.png";
import A35 from "./8_1175.png";
import A36 from "./8_1180.png";
import A37 from "./8_1185.png";
import A38 from "./8_1190.png";
import A39 from "./8_1195.png";
import A40 from "./8_1200.png";
import A41 from "./8_1205.png";
import A42 from "./8_1210.png";
import A43 from "./8_1215.png";
import A44 from "./8_1220.png";
import A45 from "./8_1225.png";
import A46 from "./8_1230.png";
import A47 from "./8_1235.png";
import A48 from "./8_1240.png";
import A49 from "./8_1245.png";
import A50 from "./8_1250.png";
import A51 from "./8_1255.png";
import A52 from "./8_1260.png";
import A53 from "./8_1265.png";
import A54 from "./8_1270.png";
import A55 from "./8_1275.png";
import A56 from "./8_1280.png";
import A57 from "./8_1285.png";
import A58 from "./8_1290.png";
import A59 from "./8_1295.png";
import A60 from "./8_1300.png";
import A61 from "./8_1305.png";
import A62 from "./8_1310.png";
import A63 from "./8_1315.png";
import A64 from "./8_1320.png";
import A65 from "./8_1325.png";
import A66 from "./8_1330.png";
import A67 from "./8_1335.png";
import A68 from "./8_1340.png";
import A69 from "./8_1345.png";
import A70 from "./8_1350.png";
import A71 from "./8_1355.png";
import A72 from "./8_1360.png";
import A73 from "./8_1365.png";
import A74 from "./8_1370.png";
import A75 from "./8_1375.png";
import A76 from "./8_1380.png";
import A77 from "./8_1385.png";
import A78 from "./8_1390.png";
import A79 from "./8_1395.png";
import A80 from "./8_1400.png";
import A81 from "./8_1405.png";
import A82 from "./8_1410.png";
import A83 from "./8_1415.png";
import A84 from "./8_1420.png";
import A85 from "./8_1425.png";
import A86 from "./8_1430.png";
import A87 from "./8_1435.png";
import A88 from "./8_1440.png";
import A89 from "./8_1445.png";
import A90 from "./8_1450.png";
import A91 from "./8_1455.png";
import A92 from "./8_1460.png";
import A93 from "./8_1465.png";
import A94 from "./8_1470.png";
import A95 from "./8_1475.png";
import A96 from "./8_1480.png";
import A97 from "./8_1485.png";
import A98 from "./8_1490.png";
import A99 from "./8_1495.png";
import A100 from "./8_1500.png";
import A101 from "./8_1505.png";
import A102 from "./8_1510.png";
import A103 from "./8_1515.png";
import A104 from "./8_1520.png";
import A105 from "./8_1525.png";
import A106 from "./8_1530.png";
import A107 from "./8_1535.png";
import A108 from "./8_1540.png";
import A109 from "./8_1545.png";
import A110 from "./8_1550.png";
import A111 from "./8_1555.png";
import A112 from "./8_1560.png";
import A113 from "./8_1565.png";
import A114 from "./8_1570.png";
import A115 from "./8_1575.png";
import A116 from "./8_1580.png";
import A117 from "./8_1585.png";
import A118 from "./8_1590.png";
import A119 from "./8_1595.png";
import A120 from "./8_1600.png";
import A121 from "./8_1605.png";
import A122 from "./8_1610.png";
import A123 from "./8_1615.png";
import A124 from "./8_1620.png";
import A125 from "./8_1625.png";
import A126 from "./8_1630.png";
import A127 from "./8_1635.png";
import A128 from "./8_1640.png";
import A129 from "./8_1645.png";
import A130 from "./8_1650.png";
import A131 from "./8_1655.png";
import A132 from "./8_1660.png";
import A133 from "./8_1665.png";
import A134 from "./8_1670.png";
import A135 from "./8_1675.png";
import A136 from "./8_1680.png";
import A137 from "./8_1685.png";
import A138 from "./8_1690.png";
import A139 from "./8_1695.png";
import A140 from "./8_1700.png";
import A141 from "./8_1705.png";
import A142 from "./8_1710.png";
import A143 from "./8_1715.png";
import A144 from "./8_1720.png";
import A145 from "./8_1725.png";
import A146 from "./8_1730.png";
import A147 from "./8_1735.png";
import A148 from "./8_1740.png";
import A149 from "./8_1745.png";
import A150 from "./8_1750.png";
import A151 from "./8_1755.png";
import A152 from "./8_1760.png";
import A153 from "./8_1765.png";
import A154 from "./8_1770.png";
import A155 from "./8_1775.png";
import A156 from "./8_1780.png";
import A157 from "./8_1785.png";
import A158 from "./8_1790.png";
import A159 from "./8_1795.png";
import A160 from "./8_1800.png";
import A161 from "./8_1805.png";
import A162 from "./8_1810.png";

export const AirPods = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

  A100,
  A101,
  A102,
  A103,
  A104,
  A105,
  A106,
  A107,
  A108,
  A109,

  A110,
  A111,
  A112,
  A113,
  A114,
  A115,
  A116,
  A117,
  A118,
  A119,

  A120,
  A121,
  A122,
  A123,
  A124,
  A125,
  A126,
  A127,
  A128,
  A129,

  A130,
  A131,
  A132,
  A133,
  A134,
  A135,
  A136,
  A137,
  A138,
  A139,

  A140,
  A141,
  A142,
  A143,
  A144,
  A145,
  A146,
  A147,
  A148,
  A149,

  A150,
  A151,
  A152,
  A153,
  A154,
  A155,
  A156,
  A157,
  A158,
  A159,

  A160,
  A161,
  A162,
);
