import React from 'react';
import { scrollToSection } from '../../helpers/globals'
import Image from '../image/Image'
import './styles.scss'

const SideBar = props => {
    const { section } = props;
    const dots = [
        '#activity',
        '#pill',
        '#operations',
        '#cooperations',
        '#caseStudies',
    ];

    let labels = [
        'About Us',
        'DBD in a nutshell',
        'Fields of activity',
        'Benefits',
        'Case Studies',
    ];

    return (
        <>
            <div className={'sidebar sidebar--' + section}>
                <div>
                    {dots.map((dot, index) => {
                        return (
                            <div key={index} onClick={() => scrollToSection(dot)} className={index === section ? 'dot dot--active dot--' + section : 'dot dot--' + section}></div>
                        )
                    })}
                </div>
            </div>
            <div className={'side-line sidebar--' + section}></div>
            {section === 'header' ? (
                <div className='left-link' onClick={() => scrollToSection('#activity')}>
                    <Image src='arrow.svg'
                        className='mx-auto shadow-xl'
                        alt='icon' />
                    <span>{labels[0]}</span>
                </div>
            ) : section !== 4 ?  (
                <div className='left-link' onClick={() => scrollToSection(dots[section + 1])}>
                    <Image src='arrow.svg'
                        className='mx-auto shadow-xl'
                        alt='icon' />
                    <span> {labels[section + 1]}</span>
                </div>
            ) : null}
        </>
    )
};


export default SideBar;